@import '../../assets/scss/__mixi.scss';
@import '../../assets/scss/v.common.scss';

.section {
	position: relative;
	width: 100%;
	padding: 118px 0;
	border-bottom: 1px solid #000;
}
.sectionWrap {
	position: relative;
}
:global(.blur-node) {
	.section {
		filter: blur(5px);
	}
	.richText {
		opacity: .3 !important;
	}
	.title,
	.reverseTitle {
		opacity: 0 !important;
		@media (max-width: 540px) {
			opacity: .3 !important;
		}
	}
}
:global {
	.p-end {
		position: absolute;
		color: $com_color;
		font-size: 21/14*1em;
		line-height: 1;
	}
	.block-img {
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
:global(.sanxin.chrome) {
	.wrap {
		p {
			font-size: 20px;
		}
	}
}


.wrap {
	position: relative;
	width: 625px;
	margin: 0 auto;
	font-family: "Roboto Mono", monospace;
	font-size: 14px;
	line-height: 30/16*1em;
	font-weight: 400;
	.updateLink {
		position: relative;
		z-index: 1;
		font-size: 16px;
		margin-top: 1em;
		@media (max-width: 361px) {
			font-size: 14px;
		}
		a {
			color: $com_color;
			&:hover {
				text-decoration: underline;
			}
		}
		.updateLickIcon {
			margin-left: 1em;
			img {
				width: 46px;
			}
		}
	}
	ul,ol {
		padding-left: 30px;
	}
	ul {
		li {
			list-style-type: disc;
		}
	}
	ol {
		li {
			list-style-type: decimal;
		}
	}
	li {
		margin-bottom: 20px !important;
	}
	p,li {
		margin-bottom: 20px;
		font-family: "Roboto Mono", monospace;
		font-size: 14px;
		line-height: 30/16*1em;
		font-weight: 400;
		text-size-adjust: none;
		&:first-child {
			margin-top: -.5em;
		}
		&:last-child {
			margin-bottom: 0;
		}
		a {
			color: $com_color;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	img {
		width: 100%;
	}
}


.titleContainer {
	position: absolute;
	left: 0;
	top: 0;
	@media (max-width: 540px) {
		position: relative;
	}
}
.reverseTitleContainer {
	position: absolute;
	top: 0;
	right: 0;
	@media (max-width: 540px) {
		position: relative;
	}
}


.title,
.reverseTitle {
	font-family: 'Cardo', sans-serif;
	font-size: 48px;
	line-height: 84/60*1em;
	letter-spacing: -0.01em;
	@media (min-width: 540px) {
		padding-left: 5px;
	}
}

.titleP,
.reverseTitleP {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
}
.reverseTitleP {
	transform: rotateZ(-90deg) translateY(-20px) rotateX(180deg) translateZ(0);
	transform-origin: right top;
	white-space: nowrap;
	cursor: pointer;
}

.titleP {
	transform: rotate(-90deg) translateX(-100%) translateY(20px) translateZ(0);
	transform-origin: left top;
}

.titlePlaceholder,
.reverseTitlePlaceholder {
	opacity: 0;
	@media (max-width: 540px) {
		display: none;
	}
}

.title {
	//translateX(-50%) translateY(100%)
	@media (min-width: 767px) {
		padding-left: 5px;
	}
}




.reverseTitle {
	&:hover {
		color: #0010FC;
	}
	.before {
		font-family: 'LucidaGrande';
		color: #0010FC;
		position: relative;
		font-size: 36px;
		left: -5px;
	}
}










@media (max-width: 1024px) and (min-width: 853px) {
	.wrap {
		width: 467px;
		p {
			line-height: lh(16,30);
		}
	}

	.section {
		padding-top: 105px;
		padding-bottom: 105px;
	}
}

@media (max-width: 853px) and (min-width: 540px) {

	.title,
	.reverseTitle {
		font-size: 50px;

	}

	.wrap {
		width: 400/853*100%;

		p {
			line-height: lh(16,30);
		}
	}

	.section {
		padding: 90px 0;
	}
}

@media (max-width: 540px) {
	.mobileTitle {
		display: none;
	}

	.title,
	.reverseTitle {
		margin-top: 0;
	}

	.wrap {
		width: 326/376*100%;
		p {
			line-height: lh(16,28);
		}
	}

	.section {
		padding-top: 62px;
		padding-bottom: 62px;
	}

	.richText {
		position: relative;
		z-index: 1;
		div.block-img {
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		// p:first-child {
		// 	margin-top: 1em;
		// }
		// .paragraphAnimator:last-child {
		// 	p:last-child {
		// 		&::after {
		// 			content: ' ¶';
		// 			color: $com_color;
		// 		}
		// 	}
		// }
		
	}
	

	.title {
		position: relative;
		top: auto;
		right: auto;
		font-size: 50px;
		line-height: 58/50*1em;
		letter-spacing: -0.001em;
		transform: none;
		text-align: center;
		margin-bottom: 23px;
	}

	.reverseTitle {
		display: none;
	}
}