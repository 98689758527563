.MultipleFooter {
	min-height: 179/50*1em;
	width: 100%;
	background: #000;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	// font-size: 50px;
	padding: 20/50*1em 81/50*1em;
	box-sizing: border-box;
	font-size: 50/1440*100vw;
}
.logoText {
	font-family: 'Roboto',sans-serif;
	font-weight: 500;
	font-size: 12/50*1em;
	line-height: 1em;
	letter-spacing: 0.02em;
	color: #ddd;
}
.logoImg {
	// width: 298/16*1em;
	display: flex;
	align-items: center;
	img {
		display: block;
	}
}
.logoImgMobile {
	display: none;
	img {
		display: block;
	}
}
.copyRight {
	font-family: 'Roboto',sans-serif;
	color: #fff;
	font-size: 16/50*1em;
	line-height: 25/16*1em;
	margin-top: .5em;
}
.partinerLogo {
	position: relative;
	display: inline-block;
	padding-right: 40/50*1em;
	padding-left: 10/50*1em;
	.lineImg {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		width: 26/50*1em;
		height: auto;
	}
}

.mobileItem {
	display: none;
}


@media (max-width: 540px) {
	.MultipleFooter {
		font-size: 16/376*100vw;
		min-height: 479/16*1em;
		padding-top: 28/16*1em;
		padding-bottom: 20/16*1em;
		flex-direction: column;
		justify-content: flex-start;
	}
	.logoImg {
		display: none;
	}
	.logoImgMobile {
		display: flex;
		align-items: center;
	}
	.copyRight {
		display: none;
	}
	.rightItem {
		display: none;
	}
	.logoText {
		font-size: 13/16*1em;
	}
	.logo {
		width: 298/16*1em;
	}
	.mobileItem {
		display: block;
	}
	.divide {
		width: 289/16*1em;
		position: relative;
		font-family: 'Roboto',sans-serif;
		text-align: center;
		margin-top: 27/16*1em;
		span {
			font-size: 12/16*1em;
			line-height: 1em;
			color: #fff;
			font-style: italic;
		}
		&::before
		,&::after {
			content: '';
			position: absolute;
			height: 1px;
			background: #fff;
			width: calc(50% - 25px);
			margin-top: 1/16*1em;
		}
		&::before {
			left: 0;
			top: 50%;
		}
		&::after {
			right: 0;
			top: 50%;
		}
	}
	.logoMobileContainer {
		margin-top: 45/16*1em;
		margin-bottom: 45/16*1em;
	}
	.logoMobileContainerItem {
		&:first-child {
			img {
				margin-top: 0;
			}
		}
		img {
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-top: 17/16*1em;
		}
	}
	.line {
		width: 290/16*1em;
		height: 1px;
		background: #fff;
	}
	.copyRightMobile {
		font-family: 'Roboto',sans-serif;
		font-weight: 500;
		font-size: 14/16*1em;
		line-height: 16/14*1em;
		color: #fff;
		text-align: center;
		margin-top: 1em;
	}
}