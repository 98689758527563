img {
	width: 100%;
}
.FAQs {
	
}
:global(.blur-node) {
	.section {
		
	}
	.wrap {
		filter: blur(5px);
		opacity: .3;
		.answer p {
			opacity: 1 !important;
		}
	}
}
.wrap {
	width: 100%;
	margin: 0 auto;
}
.FAQList {
	display: flex;
	justify-content: space-between;
	margin-bottom: 46px;
	&:last-child {
		margin-bottom: 0;
	}
}
.listItem {
	width: 622/674*100%;
}
.icon {
	display: inline-block;
	width: 48px;
	// margin-right: 25px;
	font-size: 24px;
	line-height: 28px;
}
.question {
	font-family: 'Roboto',sans-serif;
	font-weight: 900;
	font-style: italic;
	font-size: 24px;
	line-height: 28px;
	color: #0E00FF;
}
.answer {
	font-family: 'Roboto Mono',sans-serif;
	font-weight: 16px;
	line-height: 28px;
	margin-top: 1em;
}
@media (max-width: 1024px) and (min-width: 853px) {
	.wrap {
		width: 100%;
	}
}
@media (max-width: 853px) and (min-width: 540px) {
	.wrap {
		width: 100%;
	}
}
@media (max-width: 540px) {
	.wrap {
		width: 100%;
	}
	.FAQList {
		margin-bottom: 20px;
		&:first-child {
			margin-top: 57px;
		}
	}
	.question {
		font-size: 20px;
		line-height: 24/20*1em;
	}
	.answer {
		line-height: 24px;
	}
	.listItem {
		width: 288/376*100vw;
	}
}