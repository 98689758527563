

.Cover {
	position: relative;
	box-sizing: content-box;
	overflow: hidden;
	margin: 3em 0;
	& > * {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: auto;
		transform: translate(-50%,-50%);
	}
}