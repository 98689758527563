.clearfix::after {
	content: '';
	display: block;
	clear: both;
}
@font-face {
  font-family: 'LucidaGrande';
  src: url('../fonts/LucidaGrande.woff') format('woff'),
			 url('../fonts/LucidaGrande.ttf') format('ttf');
	font-weight: normal;
	font-style: italic;
}
@font-face {
  font-family: 'WorkSans-SemiBold';
  src: url('../fonts/WorkSans-SemiBold.woff') format('woff'),
			 url('../fonts/WorkSans-SemiBold.ttf') format('ttf');
	font-weight: 700;
	font-style: italic;
}
