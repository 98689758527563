body {
	cursor: crosshair;
	cursor: url(./assets/images/cursor.cur),crosshair;
	cursor: url(./assets/images/cursor.png) 13 13,crosshair;
	font-family: 'WorkSans-SemiBold',sans-serif;
}

.Index {
	opacity: 0;
	transition: opacity .5s;
	width: 100%;
	max-width: 100%;
	overflow: hidden;
	min-height: 100vh;
	&[visible] {
		opacity: 1;
	}
}

.header {
	height: 100vh;
}


:global {
	.ie {
		.blur-node {
			transform: translate3d(0,0,0);
			.ieFadeOut {
				opacity: 0 !important;
			}
		}
	}
}

.page404 {
	position: relative;
	width: 100%;
	height: 100vh;
}

.page404Wrap {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	font-family: 'Roboto Mono',sans-serif;
	z-index: 10;
	font-size: 16px;
	line-height: 30/16*1;
	width: 459px;
	text-align: center;
	white-space: wrap;
	@media (max-width: 540px) {
		width: 80%;
		font-size: 12/320*100vw;
	}
}
.address {
	color: #0e00ff;
	text-decoration: underline;
}