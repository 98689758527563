@keyframes drop {
	0%   { 
		transform: translate3d(0,0,0);
	}
	100% { 
		transform: translate3d(0,6px,0);
	}
}
//animation: drop .8s ease-in-out infinite alternate both;
.header {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	// background-image: url(./header.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	overflow: hidden;
}
.backgroundImage {
	position: absolute;
	top: 0;
	left: 0;
	height: auto;
	width: 100%;
	opacity: 0;
	z-index: -10;
	opacity: 0;
	visibility: hidden;
}
:global(.ie) {
	.wrap {
		h1 {
			span {
				color: #000;
				animation: opacityAni 600ms alternate both;
			}
			@for $i from 1 to 15 {
				span:nth-child(#{$i}) {
					animation-delay: 0.1s * $i;
				}
			}
		}
	}
}
.wrap {
	position: relative;
	width: 100%;
	h1{
		span {
			color: transparent;
			animation: blur 8s ease-out 1;
			animation-fill-mode: forwards;
		}
		@for $i from 1 to 15 {
			span:nth-child(#{$i}) {
				animation-delay: 0.1s * $i;
			}
		}
 	}
}

:global(.blur-node) {
	.title,
	.arrow {
		filter: blur(5px);
		opacity: .3;
	}
	
}
:global {
	.ie {
		.blur-node {
			.title,.arrow {
				text-shadow: 1px 1px 8px #000, -1px -1px 8px #000, 1px -1px 8px #000, -1px 1px 8px #000;
				color: #2b2b2b;
				opacity: .1 !important;
			}
		}
	}
}


.title {
	font-family: 'Cardo', serif;
	font-size: 60px;
	line-height: 52/44*1em;
	letter-spacing: -.01em;
	border-top: 2px solid #000;
	padding-top: 40px;
	margin: 0 auto;
	width: 700px;
}
.arrow {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 24px;
	text-align: center;
	cursor: pointer;
}
.arrowLabel {
	font-family: "Roboto Mono", monospace;
	font-size: 16px;
	line-height: 30px;
}
.arrowImg {
	
	&[animate-down] {
		animation: drop .6s ease-in-out 4 alternate both;
	}
	img {
		width: 32px;
	}
}
@media (max-width: 1024px) and (min-width: 540px) {
	.title {
		width: 482px;
	}
}
@media (max-width: 540px) {
	.title {
		width: 276px;
	}
	.title {
		font-size: 32px;
		line-height: 44px;
	}
}

@keyframes blur {
	0%		{text-shadow:  0 0 100px #000; opacity:0;}
	5%		{text-shadow:  0 0 90px #000;}
	15%		{opacity: 1;}
	20%		{text-shadow:  0 0 0px #000;}
	80%		{text-shadow:  0 0 0px #000;}
	85%		{opacity: 1;}
	95%		{text-shadow:  0 0 0px #000;}
	100%	{text-shadow:  0 0 0px #000; opacity:1;}
}

@keyframes opacityAni {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}