.Footer {
	height: 118/16*1em;
	padding: 0 80/16*1em;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #000;
	font-size: 16px;
	@media (max-width: 853px) {
		font-size: 16/853*100vw;
	}
}
.logoText {
	font-family: 'Roboto',sans-serif;
	font-weight: 500;
	font-size: 12/16*1em;
	line-height: 1em;
	color: #ddd;
}
.logoImg {
	// width: 298/16*1em;
	display: flex;
	align-items: center;
	img {
		width: 100%;
		display: block;
	}
}
.copyRight {
	font-family: 'Roboto',sans-serif;
	color: #fff;
	font-size: 1em;
	line-height: 25/16*1em;
}
@media (max-width: 540px) {
	.Footer {
		font-size: 16/376*100vw;
		flex-direction: column;
		height: 150/16*1em;
		padding: 34px 0 20px 0;
	}
	.Footer {
		height: 150/16*1em;
	}
	.copyRight {
		font-size: 14/161em;
	}
}