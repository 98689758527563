.Input {
	position: relative;
	font-family: 'Roboto Mono',sans-serif;
	color: #0e00ff;
	font-size: 16px;
	line-height: 25/16*1em;
	display: inline-block;
	box-sizing: border-box;
	border: 1px solid transparent;
	cursor: text;
	input {
		height: 60px;
		line-height: 25px;
	}
	input,
	textarea {
		padding-left: 20px;
		padding-right: 10px;
		border-radius: 3px;
		background: #F0F0F0;
		outline: none;
		min-height: 60px;
		height: 100%;
		width: 100%;
		&:active {
			background: #F0F0F0;
		}
		// padding-top: 16px;
	}
	textarea {
		padding-top: 16px;
	}
}
.textarea-icon {
	display: inline-block;
	margin-left: 1em;
	img {
		width: 32px !important;
		vertical-align: text-bottom;
	}
}

.placeholder {
	position: absolute;
	top: 0;
	left: 20px;
	height: 60px;
	line-height: 60px;
	display: block;
	&.focused {
		display: none;
		z-index: -1;
	}
}