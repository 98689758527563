.nav {
	position: relative;
  width: 100%;
	z-index: 100000;
}
.maskContainer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	transform: translateZ(0);
}
.title {
	position: absolute;
	font-family: 'Cardo', serif;
	font-size: 44px;
	line-height: 52px;
	transition: filter .5s;
	border-top: 2px solid #000;
	padding-top: 10px;
	margin: 0 auto;
	width: 664px;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}
:global(.ie11) {
	.title,.arrow {
		text-shadow: 1px 1px 8px #000, -1px -1px 8px #000, 1px -1px 8px #000, -1px 1px 8px #000;
		color: #2b2b2b;
		opacity: .1;
	}
}
.title,
.arrow {
	filter: blur(5px);
	-ms-filter: blur(5px);
	opacity: 0.3;
	// text-s
}
.arrow {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 24px;
	text-align: center;
	cursor: pointer;
}
.arrowLabel {
	font-family: "Roboto Mono", monospace;
	font-size: 16px;
	line-height: 30px;
}
.arrowImg {
	
	&[animate-down] {
		animation: drop .6s ease-in-out 4 alternate both;
	}
	img {
		width: 32px;
	}
}
.navWrap {
  position: fixed;
  top: 0;
  left: 0;
  height: 85px;
  width: 100%;
	background-color: #fff;
	.navWrapContainer {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 100%;
		z-index: 1000;
		padding-left: 100px;
		padding-right: 100px;
		overflow: visible;
	}
	.mask{
    width:100%;
    // height: calc(100vh - 115px);
    position: absolute;
    left:0;
    top: 0;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		transform: translate3d(0,0,0);
		// filter: blur(5px);
		will-change: transform;
		z-index: 1;
		transition: background .5s;
	}
}
.logo {
  width: 157px;
  position: relative;
  img {
    width: 100%;
  }
}
.logoImg {
	transform: translate3d(0, 0, 0);
}
.logoMenu {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
	transform: translate3d(0, 0, 0);
	opacity: .5;
}
.menuList {
  & > li {
    display: inline-block;
    margin-left: 20px;
    font-family: "Roboto Mono", monospace;
    font-size: 14px;
    font-weight: 500;
		line-height: 19px;
		padding: 12px;
		font-weight: 400;
		cursor: pointer;
    &:first-child {
      margin-left: 0;
		}
		&:hover {
			color: #1953FF;
			@media (max-width: 1024px) {
				&::before {
					content: '- ';
				}
			}
		}
    &[active] {
			color: #1953FF;
			@media (max-width: 1024px) {
				&::before {
					content: '- ';
				}
			}
    }
  }
}
.menu {
  display: none;
  font-family: "Roboto Mono", monospace;
  font-size: 14px;
	font-weight: 400;
	cursor: pointer;
  &.open {
    span {
      color: #fff;
    }
  }
  span {
    color: #000;
  }
}
.menuList1 {
  position: absolute;
}
@media (max-width: 1024px) {
  .nav {
		cursor: pointer;
    &[expanded] {
      .logoMenu {
        opacity: .5;
      }
      .logoImg {
        opacity: 0;
      }
      .menu {
        span {
          color: #fff;
        }
      }
      .menuList {
				opacity: 1;
				display: block;
      }
			.navWrap {
				.navWrapContainer {
					background: #000 !important;
				}
			}
		}
		.menuList {
			cursor: pointer;
			& > li {
				line-height: 54px;
			}
		}
	}
	.navWrap {
		.navWrapContainer {
			padding-left: 50px;
			padding-right: 50px;
		}
	}
  .menuList {
    position: absolute;
    top: 100%;
    left: 0;
		width: 100%;
		// visibility: hidden;
    padding-left: 70px;
    padding-top: 47px;
    background: #000;
		height: calc(100vh - 115px);
		transform: translate3d(0, 0, 0);
		opacity: 0;
		display: none;
		z-index: 1000;
    li {
      display: block;
      margin: 0;
      font-size: 20px;
      line-height: 44px;
      color: #fff;
      border-top: 1px solid #1953ff;
      font-weight: 400;
      padding: 3px 0;
    }
  }
  .logo {
    width: 160px;
  }
  .logoDesk {
    display: none;
  }
  .logoImg {
    transition: all 0.5s;
    display: inline-block;
  }
  .logoMenu {
    display: inline-block;
    opacity: 0;
    transition: all 0.5s;
    &.active {
      opacity: 1;
    }
  }
  .menu {
    position: relative;
    display: block;
  }
}
@media (max-width: 540px) {
  .menuList {
    height: calc(100vh - 84px);
  }
  .navWrap {
		height: 85px;
		.mask {
			// height: calc(100vh - 84px);
		}
		.navWrapContainer {
			padding-left: 25px;
			padding-right: 25px;
		}
	}
	
  .logo {
    width: 134px;
  }
}


@media (max-width: 1024px) and (min-width: 540px) {
	.title {
		width: 482px;
	}
}
@media (max-width: 540px) {
	.title {
		width: 276px;
	}
	.title {
		font-size: 36px;
		line-height: 44px;
	}
}