@import '../../assets/scss/v.common.scss';
.Contact {
}
.itemInner {
	display: inline-block;
}
.wrap {
	position: relative;
  width: 623px;
	margin: 0 auto;
}
.loadingContainer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255,.8);
	z-index: 1;
}
.inputContainer {
	position: relative;
}
.loading {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	z-index: 1;
}
.messagePage {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	font-family: 'Roboto Mono',sans-serif;
	z-index: 10;
	width: 279px;
	text-align: center;
	display: none;
	cursor: text;
	.thankyou {
		color: $com_color;
		font-weight: 700;
	}
	.description {
		margin-top: .5em;
		line-height: 20px;
	}
	.closeButton {
		width: 100px;
		height: 36px;
		line-height: 36px;
		margin-top: 16px;
	}
}
.errorLine {
	border: 1px solid rgb(240, 103, 103) !important;
}
.formItem {
	position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .itemInner {
		position: relative;
		width: calc(50% - 7px);
		@media (max-width: 540px) {
			width: 100%;
		}
	}
	.Input {
		width: 100%;
	}
  .EmailInput {
    width: 100%;
    input {
      width: 100%;
    }
  }
  .textarea {
    height: 165px;
    width: 100%;
    textarea {
      resize: none;
    }
  }
}
.button {
  width: 100%;
  outline: none;
  background: #0e00ff;
  color: #fff;
  height: 60px;
  line-height: 60px;
  border: none;
  font-family: "Roboto Mono", sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 3px;
}
.errorMessage {
	position: absolute;
	top: 100%;
	left: 0;
	font-size: 10px;
	line-height: 15px;
	width: 100%;
	text-align: center;
	color: rgb(240, 103, 103);
}
.instruction,
.address {
	font-family: 'Roboto Mono',sans-serif;
	text-align: center;
}
.instruction {
	font-size: 12px;
	line-height: 20/12*1em;
	margin-bottom: .3em;
	margin-top: 38px;
}
.address {
	a {
		font-size: 14px;
		line-height: 20/14*1em;
		color: #0e00ff;
		text-decoration: underline;
	}
}
@media (max-width: 1024px) and (min-width: 853px) {
	.wrap {
		width: 498px;
	}
}
@media (max-width: 853px) {
	.wrap {
		width: 100%;
	}
}
@media (max-width: 540px) {
	.formItem {
		flex-direction: column;
		justify-content: flex-start;
		margin-bottom: 15px;
		.Input {
			width: 100%;
		}
		.firstName {
			margin-bottom: 15px;
		}
		.firstError {
			margin-top: -15px;
		}
		.textarea {
			height: 149px;
		}
	}
	.instruction {
		margin-top: 31px;
	}
	.form {
		margin-top: 34px;
	}
}
